import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { AgilityImage } from "@agility/gatsby-image-agilitycms"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"
import { Choose } from "@organic-return/foundation-react/src/components/Search/Filter"
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'
import { useState } from "react"


const PostListing = () => {
    // graphql query for posts data
    const data = useStaticQuery(graphql`
    query {
      posts: allAgilityPost(
        filter: { properties: { referenceName: { eq: "posts" } } }
        sort: { fields: customFields___date, order: DESC }
      ) {
        nodes {
          customFields {
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              url
              height
              width
            }
            category {
              contentid
            }
          }
          sitemapNode {
            path
          }
          linkedContent_agilityCategory {
            customFields {
              title
            }
          }
        }
      }
    }
  `)

    // get posts
    const posts = data.posts.nodes

    const dateFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const pageSize = 12;

    //Enum with screen breakpoint. Will be useful throughout component
    enum EScreenBreakpoints {
        sm = "sm",
        md = "md",
        lg = "lg",
    }
    //Retrieves tailwind's configuration object
    const fullConfig = resolveConfig(tailwindConfig)
    //Retrieves all defined tailwind screens sizes breakpoints
    const { screens } = fullConfig.theme
    //Function that returns the correspondent screen size break point
    const getCurrentScreenBreakpoint = () => {
        const viewportWidth = typeof window !== "undefined" ? window.outerWidth : 0;
        const mdScreenSize = parseInt(screens.md);
        const lgScreenSize = parseInt(screens.lg);

        if (viewportWidth >= mdScreenSize && viewportWidth < lgScreenSize) {
            return EScreenBreakpoints.md;
        }
        else if (viewportWidth >= lgScreenSize) {
            return EScreenBreakpoints.lg;
        }
        else {
            return EScreenBreakpoints.sm;
        }

    }

    /*
    The idea here is to reproduce the masonry layout. So this function will reorder posts in pages by the screen size
     */
    const getSortedPostsByScreenSize = (): any => {

        let currentScreenBreakpoint = getCurrentScreenBreakpoint();

        let columns = 1;
        //Medium devices => 2 columns
        if (currentScreenBreakpoint == EScreenBreakpoints.md) {
            columns = 2;
        }
        else if (currentScreenBreakpoint == EScreenBreakpoints.lg) {
            columns = 3;
        }

        //if it's a small device breakpoint, just return posts from static query
        if (columns === 1)
            return posts;

        let sortedPosts = [];

        //Here we reorder every page posts...
        for (let p = 0; p < posts.length; p = p + pageSize) {
            let start = p;
            let end = p + pageSize;

            let postsPage = posts.slice(start, end);

            let aux = 0;
            //This feeds the columns...
            while (aux < columns) {
                for (let i = aux; i < postsPage.length; i = i + columns) {
                    sortedPosts.push(postsPage[i]);
                }
                aux++;
            }
        }
        return sortedPosts;
    }

    const [postList, setPostList] = useState(getSortedPostsByScreenSize())

    useEffect(() => {
        const handleWindowResize = () => {
            setPostList(getSortedPostsByScreenSize())
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    })


    // if there are no posts, display message on frontend
    if (posts.length <= 0) {
        return (
            <div className="mt-44 px-6 flex flex-col items-center justify-center">
                <h1 className="text-3xl text-center font-bold">No posts available.</h1>
                <div className="my-10">
                    <Link
                        to="/"
                        className="px-4 py-3 my-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary transition duration-300"
                    >
                        Return Home
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <Search
            index={postList}
            pageSize={pageSize}
            pagerBottom={true}
            showCount={true}
            className="max-w-screen-2xl mx-auto px-4"
            resultsClassName="sm:block columns-1 sm:columns-2 lg:columns-3 md:gap-10"
            renderItem={(post, index) => {
                //For lg screens, every "pageSize/3" should create another column
                //For md screens, every "pageSize/2" should create another column
                let currentScreenBreakpoint = getCurrentScreenBreakpoint()
                let breakAfterClass = ""
                if ((currentScreenBreakpoint == EScreenBreakpoints.lg && (index + 1) % (pageSize / 3) === 0) ||
                    (currentScreenBreakpoint == EScreenBreakpoints.md && (index + 1) % (pageSize / 2) === 0)) {
                    breakAfterClass = "md:break-after-column"
                }
                return (
                    <Link to={post.sitemapNode?.path} key={index}
                        className={`block w-full md:w-auto break-inside-avoid mb-10 ${breakAfterClass}`}>
                        <div>
                            <div className="relative">
                                <AgilityImage
                                    image={post.customFields.image}
                                    className="rounded-t-lg"
                                    width={post.customFields.image.width}
                                    height={post.customFields.image.height}
                                    layout="fullWidth"
                                />
                            </div>
                            <div className="bg-gray-100 p-8 border-2 border-t-0 rounded-b-lg">
                                <div className="uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose">
                                    {post.linkedContent_agilityCategory?.customFields.title ||
                                        "Uncategorized"}
                                </div>
                                <div className="border-b-2 border-primary-500 w-8"></div>
                                <h2 className="text-secondary-500 mt-1 font-black text-2xl group-hover:text-primary-500 transition duration-300">
                                    {post.customFields.title}
                                </h2>
                                <div>
                                    {`Posted on ${new Date(post.customFields.date).toLocaleDateString("en-US", dateFormatOptions)}`}
                                </div>
                            </div>
                        </div>
                    </Link>
                )
            }}
            preRenderItem={(item, index) => {
                return (
                    <a key={index} href={item.sitemapNode?.path}></a>
                )
            }}
        />
    )
}

export default PostListing
