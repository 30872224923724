import PostsListing from "./PostsListing"
import FooterModule from "./FooterModule"
import VideosListing from "./VideosListing"
import NewestListings from "./NewestListings"
import ExclusiveListings from "./ExclusiveListings"
import PartnersListing from "./PartnersListing"
// All of the Agility Page Module Components that are in use in this site need to be imported into this index file.
// Place Page Modules in allModules array below, passing in a name and the component.

const allModules: any[] = [
  { name: "PostsListing", module: PostsListing },
  { name: "FooterModule", module: FooterModule },
  { name: "VideosListing", module: VideosListing },
  { name: "NewestListings", module: NewestListings },
  { name: "ExclusiveListings", module: ExclusiveListings },
  { name: "PartnersListing", module: PartnersListing },
]

export const getModule = (moduleName: string) => {
  if (!moduleName) return null
  const obj = allModules.find(
    m => m.name.toLowerCase() === moduleName.toLowerCase()
  )
  if (!obj) return null
  return obj.module
}
