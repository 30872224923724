import { useSiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import ListingCard, { EListingCardLayouts } from "@organic-return/foundation-react/src/components/Listing/ListingCard"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { Search } from "@organic-return/foundation-react/src/components/Search"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const NewestListings = ({ module }) => {
  const siteContext = useSiteContext()
  const data = useStaticQuery(graphql`
    query {
        allAgilityNewestListings {
        nodes {
          customFields {
            listings {
                id
                baths
                address {
                  city
                  street1
                  county
                  postal
                  text
                }
                beds
                lotacres
                majorArea
                minorArea
                mlsID
                price
                sqft
                status
                statusTS
                subType
                subdivision
                exclusive
                features
                flags
                type
                defaultImage {
                  dimensions
                  url
                }
                path
                location {
                  lat
                  lng
                }
                hideOnMap
            }
          }
          contentID
        }
      }
    }
  `)

  const result = data.allAgilityNewestListings.nodes.find(node => node.contentID == module.contentID)

  return (
    <Search
      index={result.customFields.listings}
      pageSize={result.length}
      resultsClassName="max-w-screen-2xl mx-auto"
      pagerBottom={false}
      showCount={false}
      renderItem={(item, index) => {
        return (
          <ListingCard
            listing={item}
            layout={EListingCardLayouts.vertical}
            className={`w-[480px] max-w-full`}
            maxWidthImage={480}
            vitalsLayout={EListingVitalsLayout.column2Border1}
            linkImage={true}
            linkFunc={props => {
              return (
                <Link
                  to={props.href}
                  className={`text-inherit ${props.className}`}
                  key={props.key}
                  title={props.title}
                >
                  {props.children}
                </Link>
              )
            }}
            {...siteContext.globalListingCardProps}
          />
        )
      }}
      preRenderItem={(item, index) => {
        return (
          <a key={index} href={item.path}></a>
        )
      }}
    >
    </Search>
  )
}

export default NewestListings