import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  ContactCard,
  EContactCardLayout,
} from "@organic-return/foundation-react/src/components/Contact/ContactCard"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"
import { useSiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"

const PartnersListing = ({ module }: any) => {
  const siteContext = useSiteContext()
  const data = useStaticQuery(graphql`
    query {
      allPerson(sort: {fields: last, order: ASC}) {
        nodes {
          bio
          company
          email
          first
          fullName
          last
          mlsID
          officeName
          originID
          path
          phone
          phoneMobile
          phoneOffice
          workTitle
          social {
            channel
            url
          }
          photos {
            url
          }
        }
      }
      partners: allAgilityPartners {
        nodes {
          contentID
          customFields {
            partnerTypeID
            agentLastName
            agentFirstName
            agentDashID
            locationName
          }
          extraLocations {
            customFields {
              locationName
            }
          }
        }
      }
      allAgilityPartnerType {
        nodes {
          customFields {
            title
          }
          contentID
        }
      }
    }
  `)

  const fields = module.customFields
  const partners = data.partners.nodes.filter((partner: any) => {
    return partner.customFields.partnerTypeID == fields.partnerTypeID
  })

  let partnersMatch = data.allPerson.nodes.filter((person: any) => {
    return partners.some((partner: any) => {
      return partner.customFields.agentFirstName == person.first && partner.customFields.agentLastName == person.last
    })
  })

  const partnersLocation: any = {}
  partners?.forEach((partner: any) => {
    const location: string[] = []
    const { locationName } = partner.customFields
    locationName && location.push(locationName)
    partner.extraLocations?.forEach((extra: any) => {
      const { locationName } = extra.customFields
      location.push(locationName)
    })
    partnersLocation[partner.contentID.toString()] = location
  })

  const partnerType = data.allAgilityPartnerType.nodes.find((item: any) => {
    return item.contentID == fields.partnerTypeID
  })

  let partnersToDisplay = partnersMatch?.map((partner: any) => {
    return { ...partner, location: partnersLocation[partner.originID] }
  })

  if (partnerType.customFields.title == "Klug Team") {
    const klug = partnersToDisplay.filter((person: any) => {
      return person.first == "Chris" && person.last == "Klug"
    })
    const notKlug = partnersToDisplay.filter((person: any) => {
      return person.first != "Chris" || person.last != "Klug"
    })
    partnersToDisplay = klug.concat(notKlug)
  }

  if (partnerType.customFields.title === "Market Leader") {
    siteContext.partnersListingProps = {
      ...siteContext.partnersListingProps,
      headerClassName: "max-w-screen-lg mx-auto flex-wrap",
      pagerClassName: "max-w-screen-lg mx-auto",
      resultsClassName: "max-w-screen-xl mx-auto pb-24 !grid md:!grid-cols-2 xl:!grid-cols-3 [&_.contact-card]:w-full",
      filterClassName: "w-full bg-body-bg-inverted h-28 flex flex-col justify-center [&_.search-filters]:w-full [&_.search-filters]:!max-w-screen-lg [&_.search-filters]:mx-auto [&_.search-filters-primary]:!max-w-full",
      showCount: false,
      pageSize: 12,
      pagerBottom: true,
    }
  }
  else {
    siteContext.partnersListingProps = {
      headerClassName: "max-w-screen-lg mx-auto",
      pagerClassName: "max-w-screen-lg mx-auto",
      resultsClassName: "max-w-screen-xl mx-auto",
      className: "inverted",
      showCount: false,
    }
  }

  return (
    <Search
      index={partnersToDisplay}
      pageSize={0}
      showCount={true}
      renderItem={(item, index) => {
        return (
          <ContactCard
            contact={item}
            layout={EContactCardLayout.horizontal}
            maxWidthImage={200}
            className="w-full sm:w-1/2 xl:w-1/3 p-4"
            showVCard={false}
            showAddress={true}
            buttonText={`About ${item.first || item.fullName}`}
            key={index}
            linkFunc={props => {
              return (
                <Link
                  to={props.href ?? ""}
                  className={`text-inherit ${props.className}`}
                  key={props.key}
                  title={props.title}
                >
                  {props.children}
                </Link>
              )
            }}

            {...siteContext.globalPeopleSearchCardProps}
          />
        )
      }}
      onPageChangeScrollTo={() => {
        const targetSection = document.getElementsByClassName('search')?.[0];
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }}
      {...siteContext.partnersListingProps}
    />
  )
}

export default PartnersListing
