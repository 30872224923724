import React from "react"
import SiteFooter from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteFooter"

const FooterModule = () => {

    return (
        <SiteFooter />
    )
}

export default FooterModule