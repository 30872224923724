import Search from "@organic-return/foundation-react/src/components/Search/Search"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"


const VideosListing = () => {
  const data = useStaticQuery(graphql`
    query {
        allAgilityVideos(sort: {fields: properties___itemOrder, order: ASC}) {
          nodes {
            customFields {
              content
              contentTruncated
              path
              title
              videoID
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
    }
  `)

  const videos = data.allAgilityVideos.nodes
  const siteUrl = data.site.siteMetadata.siteUrl

  return (
    <Search
      pageSize={10}
      pagerBottom={true}
      index={videos}
      className="max-w-screen-2xl mx-auto px-4"
      resultsClassName="flex-col gap-9"
      renderItem={(video, index) => {
        return (
          <div key={index} className="w-full grid grid-cols-3 gap-4 lg:gap-9">
            <div className="col-span-3 lg:col-span-1">
              <iframe className="w-full" src={`https://www.youtube.com/embed/${video.customFields.videoID}?origin=${siteUrl}&modestbranding=1;autohide=1&amp;showinfo=0&amp;controls=0;feature=oembed`} height="250" width="100%"></iframe>
            </div>
            <div className="col-span-3 lg:col-span-2">
              <h2>{video.customFields.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: video.customFields.contentTruncated }}></div>
              <Link to={video.customFields.path} className="inline-block mt-4 px-8 py-3 font-semibold text-button-text bg-button-bg">
                Learn more
              </Link>
            </div>
          </div>
        )
      }}
      preRenderItem={(item, index) => {
        return (
          <a key={index} href={item.customFields.path}></a>
        )
      }}
    >
    </Search>
  )
}

export default VideosListing